<template>
  <div>
    <!-- 商机池订单详情 -->

    <el-dialog title="订单详情"
               :visible.sync="orderPooldialogVisible"
               width="90%"
               :before-close="handleClose">
      <el-card class="box-card">
        <div slot="header"
             class="clearfix">
          <span>基础信息</span>
        </div>
        <el-form ref="form"
                 :model="form"
                 label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="客户姓名">
                <span>{{ orderPoolInfo.contacts }}</span>
                <el-tag style="margin-left: 20px"
                        effect="dark"
                        type="info">{{
                  orderPoolInfo.isVip == 1 ? "会员" : "非会员"
                }}</el-tag>
                <el-tag style="margin-left: 20px"
                        effect="dark"
                        type="danger">{{
                  orderPoolInfo.newOrOldUser == 0 ? "新用户" : "老用户"
                }}</el-tag>
                <el-tag style="margin-left: 20px"
                        effect="dark"
                        type="warning">{{ orderPoolInfo.sex }}</el-tag>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话">
                <span>{{ orderPoolInfo.telephone }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系微信">
                <span>{{ orderPoolInfo.weChat }}</span>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="客户地址">
                <span>{{ orderPoolInfo.addres }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="备注">
                <span>{{ orderPoolInfo.remarks }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card class="box-card">
        <div slot="header"
             class="clearfix">
          <span>商机信息</span>
        </div>
        <el-form ref="form"
                 :model="form"
                 label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="商机类型">
                <span>{{
                  orderPoolInfo.dispatchType == 1 ? "有效商机" : "一般商机"
                }}</span>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="商机来源">
                <span>{{ orderPoolInfo.title }}</span>
                <span v-if="orderPoolInfo.titles2 != null">
                  {{ "--" + orderPoolInfo.titles2 }}
                </span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="问题类型">
                <span>{{ orderPoolInfo.pro_id_txt }}</span>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="预计跟进时间">
                <span>{{ orderPoolInfo.specifiedTime_txt }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>

      <el-card class="box-card">
        <div slot="header"
             class="clearfix">
          <span>处理记录</span>
        </div>
        <el-table :data="orderPoolInfo.record"
                  style="width: 100%">
          <el-table-column prop="created_time"
                           label="处理时间"
                           width="180">
          </el-table-column>
          <el-table-column prop="username"
                           label="处理人"
                           width="180">
          </el-table-column>
          <el-table-column prop="handling_method"
                           label="处理方式">
          </el-table-column>
        </el-table>
      </el-card>

      <el-card class="box-card">
        <div slot="header"
             class="clearfix">
          <span>沟通记录</span>
        </div>
        <el-table :data="orderPoolInfo.through"
                  style="width: 100%"
                  border>
          <el-table-column prop="username"
                           label="处理人"
                           width="180">
          </el-table-column>
          <el-table-column prop="title"
                           label="方式"
                           width="100">
          </el-table-column>
          <el-table-column prop="conversation"
                           label="是否接通"
                           width="180">
          </el-table-column>
          <el-table-column prop="calltimelength"
                           label="接通时长"
                           width="180">
          </el-table-column>
          <el-table-column prop="remar"
                           label="沟通内容"
                           width="180">
          </el-table-column>
          <el-table-column label="沟通录音"
                           width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.monitorfilename != ''">
                <audio style="width: 100%; outline: none"
                       :src="scope.row.monitorfilename"
                       controls="controls"></audio>
                <!-- <audio :src="scope.row.monitorfilename" loop="loop"></audio> -->
                <!-- <audioplay :recordFile="scope.row.monitorfilename"></audioplay> -->
              </div>
              <div v-else>暂无音频</div>
            </template>
          </el-table-column>
          <el-table-column prop="ring"
                           label="拨打时间"
                           width="180">
          </el-table-column>
          <el-table-column prop="end"
                           label="结束时间"
                           width="180">
          </el-table-column>
          <el-table-column prop="end_time"
                           label="下次跟进"
                           width="180">
          </el-table-column>
          <el-table-column prop="log"
                           label="图片"
                           width="180">
            <template slot-scope="scope"
                      v-if="scope.row.log[0]">
              <el-image style="width: 100px; height: 100px"
                        :src="scope.row.log[0]"
                        :preview-src-list="scope.row.log">
              </el-image>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card class="box-card"
               v-if="orderPoolInfo.state != 1 && orderPoolInfo.state != 2">
        <!-- <el-button type="success">派单</el-button> -->
        <el-button type="info"
                   @click="edit">编辑信息</el-button>
        <el-button type="warning"
                   @click="Conact(2)">电话联系客户</el-button>
        <el-button type="danger"
                   @click="Conact(1)">微信联系客户</el-button>
      </el-card>
    </el-dialog>
    <!-- 微信联系客户 -->
    <el-dialog :title="conactMode == 1 ? '微信联系' : '电话联系'"
               :visible.sync="wechatDialog"
               width="50%"
               :before-close="wechatDialogClose">
      <el-form ref="form"
               :model="wechatForm"
               label-width="100px">
        <el-form-item label="预计跟进时间"
                      prop="constructionTime"
                      style="height: 80px">
          <el-radio-group v-model="wechatForm.constructionTime">
            <el-row style="margin-top: 13px">
              <el-radio label="1">按分钟</el-radio>
              <el-radio label="2">按天</el-radio>
              <el-radio label="3">自定义时间</el-radio>
            </el-row>
          </el-radio-group>
          <el-radio-group v-model="wechatForm.specifiedTime"
                          v-if="
              wechatForm.constructionTime == '1' ||
              wechatForm.constructionTime == '2'
            ">
            <div style="margin-top: 20px">
              <el-radio :label="item"
                        v-for="(item, index) in wechatForm.constructionTime == '1'
                  ? times1
                  : times2"
                        :key="index">{{
                  wechatForm.constructionTime == "1"
                    ? item + "分钟"
                    : "第" + item + "天"
                }}</el-radio>
            </div>
          </el-radio-group>
          <div v-else>
            <el-date-picker v-model="wechatForm.specifiedTime"
                            type="datetime"
                            placeholder="选择日期时间">
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea"
                    v-model="wechatForm.remarks"></el-input>
        </el-form-item>
        <el-form-item label="图片"
                      v-if="conactMode == 1">
          <!-- 图片上传 -->
          <div class="blocks2 flex_items flex_start">
            <el-upload drag
                       action="https://public.yiniao.co/api/v1/upload"
                       list-type="picture-card"
                       :multiple="mutrue"
                       :on-preview="handlePictureCardPreview"
                       :on-success="upsuccess"
                       :on-remove="afterRemove"
                       :file-list="fileList"
                       :data="tuzhi">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="tudiag"
                       :modal="false">
              <img width="100%"
                   :src="dialogImageUrl"
                   alt />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button v-if="conactMode == 2"
                     @click="waihu"
                     type="warning"
                     plain>外呼客户</el-button>
          <el-button type="primary"
                     @click="submitForm(3)">保存跟进信息</el-button>
          <el-button @click="submitForm(1)">放弃商机跟进</el-button>
          <el-button @click="submitForm(2)">指派订单</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 创建商机 -->
    <AddClueOrder ref="AddClueOrder"
                  :order_pool_id="orderPoolInfo.order_pool_id"></AddClueOrder>
    <PoolToAsign :poolOrderInfo="form"
                 ref="PoolToAsign"></PoolToAsign>
  </div>
</template>
<script>
export default {
  components: {
    AddClueOrder: () => import('@/components/addClueOrder.vue'),
    PoolToAsign: () => import('@/components/PoolToAsign.vue')
  },
  name: 'PoolInfo',
  props: ['orderPoolInfo'],
  data () {
    return {
      conactMode: null, // 联系方式1微信2电话
      wechatForm: {
        constructionTime: '1', // 预计时间
        specifiedTime: '', // 自定义时间（时间戳）
        remarks: '' // 备注
      },
      // 图片上传
      mutrue: true,
      dialogImageUrl: '',
      tudiag: false,
      tuzhi: { app_name: 'public' },
      imgs: [],
      fileList: [],

      // ------------------------------------>分割线
      times1: ['5', '10', '20', '30', '60'], // 按分钟
      times2: ['2', '3', '5', '7', '10'], // 按天
      wechatDialog: false, // 微信联系弹窗
      form: {},
      // 沟通记录
      communicationRecord: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }
      ],
      // 处理记录
      recordsList: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      // 商机池订单详情状态
      orderPooldialogVisible: false,
      iCount: '', // 轮询
      call: ''
    }
  },
  mounted () {
    // this.getInfo();
  },
  watch: {
    wechatDialog: {
      handler (newVal, oldVal) {
        if (newVal == true) {
          clearInterval(this.iCount)
        }
      },
      immediate: true,
      deep: true // 可以深度检测到 person 对象的属性值的变化
    }
  },
  created () {},
  methods: {
    // 调用父组件
    getLists () {
      this.$parent.getLists()
    },
    // 编辑信息
    edit () {
      this.orderPooldialogVisible = false
      this.$refs.AddClueOrder.getInfo()
      // this.$refs.AddClueOrder.showDialog();
    },
    // 微信联系客户弹窗
    Conact (e) {
      this.conactMode = e
      this.wechatDialog = true
    },

    myTimeToLocal (inputTime) {
      if (!inputTime && typeof inputTime !== 'number') {
        return ''
      }
      var localTime = ''
      inputTime = new Date(inputTime).getTime()
      const offset = new Date().getTimezoneOffset()
      localTime = new Date(inputTime - offset * 60000).toISOString()
      localTime = localTime.substr(0, localTime.lastIndexOf('.'))
      localTime = localTime.replace('T', ' ')
      return localTime
    },
    // 保存跟进信息
    submitForm (k) {
      if (k == 2) {
        this.wechatDialog = false
        this.PoolToAsignDialog = true
        this.$refs.PoolToAsign.handleShow(this.orderPoolInfo.order_pool_id)
      } else {
        this.util
          .post(this.HOST + '/OrderPool/Through', {
            remar: this.wechatForm.remarks,
            mode: this.conactMode, // 1微信2电话
            order_pool_id: this.orderPoolInfo.order_pool_id,
            constructionTime: this.wechatForm.constructionTime,
            specifiedTime:
              this.wechatForm.constructionTime == 3
                ? this.myTimeToLocal(this.wechatForm.specifiedTime)
                : this.wechatForm.specifiedTime, // 自定义时间（时间戳）
            log: this.imgs,
            conversation_id: this.call == '' ? 0 : this.call,
            result: k // 1保存2放弃3指派
          })
          .then((res) => {
            if (res.code == 200) {
              // 保存成功重新获取详情
              this.util
                .get(this.HOST + '/OrderPool/info', {
                  orderPoolId: this.orderPoolInfo.order_pool_id
                })
                .then((res) => {
                  // 获取成功重新赋值  并清空表单信息
                  if (res.code == 200) {
                    this.orderPoolInfo = res.data
                    Object.keys(this.wechatForm).map((item) => {
                      this.wechatForm[item] = ''
                    })
                  }
                })
              // 关闭跟进弹窗
              this.wechatDialog = false
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'error',
                duration: 1200,
                offset: 90
              })
            }
          })
      }
    },
    seeInfo () {
      this.orderPooldialogVisible = true
    },
    wechatDialogClose () {
      this.wechatDialog = false
      // clearInterval(this.iCount);
    },
    handleClose () {
      this.orderPooldialogVisible = false
    },
    // 发起外呼
    waihu (aa) {
      let _this = this
      console.log(' _this.$jsonp', _this.$jsonp)

      let timestamp = new Date().getTime()
      // 打包后
      _this
        .$jsonp(_this.HOST4, {
          account: 'N000000015324',
          appid: 'ncvsorfw58bk2o6r',
          secret: '31792f00-d2e5-11e9-8be9-b3731625d6b2',
          output: 'jsonp'
        })
        .then((res) => {
          if (res.success) {
            // console.log(res, "44444444444");
            _this.lunxun(timestamp) // 轮询
            _this
              .$jsonp(_this.HOST5, {
                phonenum: _this.orderPoolInfo.telephone,
                integratedid: sessionStorage.getItem('integratedid'),
                accessToken: res.accessToken,
                account: 'N000000015324',
                flag: 106,
                ExternalData: 'main:' + timestamp + '-shopowner',
                output: 'jsonp'
              })
              .then((res) => {
                if (res.success) {
                  // console.log(res);
                }
              })
          }
        })
    },
    // 图片上传
    uploadFile (file) {
      this.formDate.append(file, file.file)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.tudiag = true
    },
    upsuccess (response, file, fileList) {
      // //console.log(response);
      this.imgs.push(response.data.file.path)
    },
    afterRemove (file, fileList) {
      for (var i = 0; i < this.imgs.length; i++) {
        if (this.imgs[i] == file.response.data.file.path) {
          this.imgs.splice(i)
        }
      }
    },
    lunxun (timestamp) {
      // this.waihulun = 1;
      let that = this
      let arr = []
      that.iCount = window.setInterval(function () {
        that.$http
          .get(that.HOST + '/Callback/jie', {
            params: {
              ExternalData: timestamp + '-shopowner'
            }
          })
          .then((res) => {
            if (res.data.code == 200) {
              that.call = res.data.data.id
              clearInterval(that.iCount)
              // console.log(res);
            } else {
              if (arr.length == 35) {
                clearInterval(that.iCount)
              }
            }
          })
          .catch(function (error) {
            // 请求失败处理
            window.console.log(error)
          })
      }, 2000)
    }
  }
}
</script>
<style></style>
